import {datadogRum} from '@datadog/browser-rum';
import * as ga from './ga';
import config from '../config';

import type {SegmentOptions, ActivityLogData} from './types/segment.types';

export const sendSegment = (event: string, options: SegmentOptions) => {
    try {
        if (event === 'track') {
            if (!config.isProd) {
                console.log(options.name);
            }
            ga.event({
                action: options.name,
                params: options.data,
            });
            datadogRum.addAction(options.name || '', options.data);
        }
    } catch (e) {
        console.error(e);
    }
};

export const sendLog = (log: string = '', context = {}) => {
    try {
        if (!config.isProd) {
            console.log(log);
        }
        if (typeof log === 'string') {
            datadogRum.addAction(log, context);
        }
        ga.event({
            action: log,
            params: context,
        });
    } catch (e) {
        console.error(e);
    }
};

export const sendActivityLog = ({uid, rid, action, status, message}: ActivityLogData) => {
    (async () => {
        try {
            const url = `/api/log-event`;
            const data = {
                uid,
                rid,
                action,
                status,
                message,
            };
            // send POST request via fetch
            await fetch(url, {
                method: 'POST',
                mode: 'same-origin',
                credentials: 'same-origin',
                keepalive: false,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
        } catch (error) {
            console.error('SengActivityLog Error:', error);
        }
    })();
};

export default sendSegment;
