import React from 'react';
import {useTranslations} from 'next-intl';
import {Content} from './Styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import type {LoadingProps} from './types/Loading';

const Loading: React.FC<LoadingProps> = ({hideText}) => {
    const t = useTranslations('components.Loading');
    return (
        <Container maxWidth="sm">
            <Content>
                {!hideText && (
                    <Typography variant="h2" color="textSecondary" gutterBottom>
                        {t('title')}
                    </Typography>
                )}
                <CircularProgress />
            </Content>
        </Container>
    );
};

export default Loading;
